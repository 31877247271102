<template>
  <div class="wrapper">
    <van-nav-bar
      title="收成记录"
      left-arrow
      left-text="返回"
      @click-left="onClickLeft"
    />
    <van-empty description="无记录" v-if="dataList.length === 0" />
    <van-list>
      <card-item
        v-for="(item, index) in dataList"
        :key="index"
        :date="item.update_time"
        :index="index"
        :totalIncome="(item.price * item.quantity).toFixed(2)"
        :acreIncome="((item.price * item.quantity) / item.area).toFixed(2)"
        :area="item.area"
        :cropYield="item.quantity"
        :unitPrice="item.price"
        :id="item.id"
        @remove="remove"
        :crop_name="item.crop_name"
        :crop_type_name="item.crop_type_name"
        :land_name="item.land_name"
        :cultivation_condition_name="item.cultivation_condition_name"
      />
    </van-list>
    <div style="margin: 16px" v-if="dataList.length !== 0">
      <van-button
        round
        block
        class="record-button"
        native-type="button"
        to="/home/recordHarvest"
      >
        记收成
      </van-button>
    </div>
  </div>
</template>
<script>
import { NavBar, List, Button, Empty } from 'vant';
import { getHarvestList } from '@/service/harvest.js';
import CardItem from './CardItem.vue';
export default {
  name: 'RecordHarvest',
  data() {
    return {
      visible: false,
      dataList: []
    };
  },
  components: {
    CardItem,
    VanNavBar: NavBar,
    VanList: List,
    VanButton: Button,
    VanEmpty: Empty
  },
  created() {
    getHarvestList().then((res) => {
      this.dataList = res.data;
    });
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    remove(n) {
      this.dataList.splice(n, 1);
      location.reload();
    }
  }
};
</script>
<style lang='scss' scoped>
$color: #001f90;
.wrapper {
  .record-button{
    background: $color;
    color:#FFF;
  }
}
</style>
