<template>
  <div class="card-item van-hairline--surround">
    <van-collapse v-model="activeNames">
      <van-collapse-item title="标题1" name="1">
        <template #title>
          <div class="date">
            <span class="num">{{ index + 1 }}</span>
            <span class="num">{{ crop_name }}</span>
            <h5>{{ date }}</h5>
          </div>
          <van-field :value="crop_type_name" label="品种" center readonly>
          </van-field>
          <van-field :value="land_name" label="地块名称" center readonly>
          </van-field>
          <van-field
            :value="cultivation_condition_name"
            label="栽培条件"
            center
            readonly
          >
          </van-field>
          <van-field :value="totalIncome" label="总收入" center readonly>
            <template #extra>
              <span>元</span>
            </template>
          </van-field>
          <van-field :value="acreIncome" label="亩收入" center readonly>
            <template #extra>
              <span>元</span>
            </template>
          </van-field>
        </template>
        <template #default>
          <!-- 种植面积 -->
          <van-field :value="area" label="种植面积" center readonly>
            <template #extra>
              <span>亩</span>
            </template>
          </van-field>
          <!-- 产量 -->
          <van-field :value="cropYield" label="本次卖出" center readonly>
            <template #extra>
              <span>kg</span>
            </template>
          </van-field>
          <!-- 单价 -->
          <van-field :value="unitPrice" label="单价" center readonly>
            <template #extra>
              <span>元/kg</span>
            </template>
          </van-field>
          <van-button
            type="danger"
            round
            block
            style="margin-top: 24px"
            @click="deleteItem(index)"
            >删 除</van-button
          >
        </template>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>
<script>
import { Field, Collapse, CollapseItem, Button, Dialog } from "vant";
import { deleteHarvest } from "@/service/harvest.js";
export default {
  name: "CardItem",
  props: {
    date: String,
    index: Number,
    totalIncome: String,
    acreIncome: String,
    area: Number,
    cropYield: Number,
    unitPrice: Number,
    id: String,
    remove: Function,
    crop_name: String,
    crop_type_name: String,
    land_name: String,
    cultivation_condition_name: String
  },
  data() {
    return {
      visible: false,
      activeNames: []
    };
  },
  components: {
    VanField: Field,
    VanCollapse: Collapse,
    VanCollapseItem: CollapseItem,
    VanButton: Button
  },
  created() {},
  methods: {
    deleteItem(index) {
      Dialog.confirm({
        title: "提醒",
        message: "确认删除此条记录？"
      })
        .then(() => {
          deleteHarvest(this.id).then(
            (res) => {
              this.$emit("remove", index);
            },
            (err) => {
              console.log(err);
            }
          );
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang='scss' scoped>
$color: #001f90;
.wrapper {
  .card-item {
    margin: 8px;
    .date {
      display: flex;
      align-items: center;
      .num {
        margin: 16px;
      }
    }
  }
}
</style>
